/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * AXIA CONFIDENTIAL                                                             *
 * ------------------                                                            *
 *                                                                               *
 * This file, project or its parts can not be copied and/or distributed without  *
 * the express permission of Axia Digital Solutions LTDA.                        *
 *                                                                               *
 * Project: @axia/atm-lib                                                        *
 * @file: transaction.status.enum.ts                                             *
 * @Date: Monday, 22nd March 2021                                                *
 * @author: Nayara Goulart (nayara.goulart@axiadigitalsolutions.com)             *
 * ----                                                                          *
 * Last Modified: Monday, 22nd March 2021                                        *
 * Modified By: Nayara Goulart (nayara.goulart@axiadigitalsolutions.com)         *
 * ----                                                                          *
 * Copyright (C) 2020 Axia Digital Solutions LTDA - All Rights Reserved.         *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export enum TransactionStatus {
    APPROVED = "APPROVED",
    WAITING = "WAITING",
    CANCELLED = "CANCELLED",
    ALL = "ALL"
}