import { FormGroup, AbstractControl } from '@angular/forms';

export function matchingPasswords(passwordKey: string, confirmPasswordKey: string): any {
    return (group: FormGroup): {[key: string]: any} => {
        const password = group.controls[passwordKey];
        const confirmPassword = group.controls[confirmPasswordKey];

        if (password.value !== confirmPassword.value) {
            return {
                mismatchedPasswords: true
            };
        }
    };
}

export function passwordPrerequisites(control: AbstractControl): any {
    const password = control.value;
    let hasLowerCase: boolean;
    let hasUpperCase: boolean;
    let hasNumber: boolean;
    let hasSpecialChar: boolean;

    const lowerCaseLetters = /[a-z]/g;
    if (password.match(lowerCaseLetters)) {
        hasLowerCase = true;
    }

    const upperCaseLetters = /[A-Z]/g;
    if (password.match(upperCaseLetters)) {
        hasUpperCase = true;
    }

    const numbers = /[0-9]/g;
    if (password.match(numbers)) {
        hasNumber = true;
    }

    const specialCharacter = /[-!@#$%^*()_+.]/g;
    if (password.match(specialCharacter) && password.length !== 0) {
        hasSpecialChar = true;
    }

    if (!hasLowerCase || !hasUpperCase || !hasNumber || !hasSpecialChar) {
        return { prerequisites: true };
    }
    else {
        return undefined;
    }
}