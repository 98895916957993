export enum E_PERSONAL_DOCUMENT_TYPE{
    SELFIE = 'SELFIE',
    IDENTIFICATION = 'IDENTIFICATION',
    ADDRESS = 'ADDRESS',
    BANK_SELFIE = 'BANK_SELFIE',
    BANK_IDENTIFICATION = 'BANK_IDENTIFICATION',
    BANK_ADDRESS = 'BANK_ADDRESS',
    BANK_COMPANY_ADDRESS = 'BANK_COMPANY_ADDRESS',
    BANK_LETTER_OF_ATTORNEY = 'BANK_LETTER_OF_ATTORNEY',
    COMPANY_SOCIAL_CONTRACT = 'COMPANY_SOCIAL_CONTRACT'
}

export class IUserDocuments{
    public id: string;
    public user_id: string;
    public document_type: E_PERSONAL_DOCUMENT_TYPE;
    public document_url: string;
    public created_at: Date;
    public last_update: Date;
    public isGeneric?: boolean;
}

export class IUserDocumentsUpdate{
    public documents: {
        document_type: E_PERSONAL_DOCUMENT_TYPE;
        document_url: string;
    }[]
}

export enum PERSONAL_DOCUMENTS_FILTER {
    KYC = 'KYC',
    BANK = 'BANK'
}