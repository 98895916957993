export interface IBillCheckRequest {
    barCode: BarCode;
    externalTerminal: string;
    externalNSU: string;
}

export class BarCode {
    public barCode: string;
    public type: string;
    public digitable: string;
}

export class BillCheckRequest implements IBillCheckRequest {
    public barCode: BarCode;

    public externalTerminal: string;

    public externalNSU: string;

    constructor(object: BillCheckRequest) {
        this.barCode = object?.barCode;
        this.externalNSU = object?.externalNSU;
        this.externalTerminal = object?.externalTerminal;
    }
}

export class IBillCheckResponse {
    public assignor: string; //Cedente do pagamento consultado
    public registerData: IBillData;
    public settleDate: Date; //Informa a data em que o pagamento será enviado para liquidação
    public dueDate: Date; //Retorna a data de vencimento extraída do código
    public endHour: string; //Informa a Hora corte do convênio (HH:MM)
    public initeHour: string; //Informa a Hora recebimento Inicial do convênio (HH:MM)
    public nextSettle: string; //Informa se o título será liquidado no próximo dia útil. Pode ser N ou S.
    public digitable: string; //Define a linha digitável consultada
    public transactionId: string; //Protocolo de identificação da operação
    public type: string; //Tipo de conta que está realizando a consulta
    public value: string; //Valor extraído do código
    public maxValue: string; //Valor máximo permitido para pagamento do título. Utilizado nos cenários onde o “AllowChangeValue” for “True”.
    public minValue: string; //Valor mínimo permitido para pagamento do título. Utilizado nos cenários onde o “AllowChangeValue” for “True”.
    public errorCode: string; //Define o código status da operação
    public message: string; //Informa descrição do erro
    public status: string; //Define o status da operação
}

export class IBillData {
    public documentRecipient: string;
    public documentPayer: string;
    public payDueDate: string;
    public nextBusinessDay: string;
    public dueDateRegister: string;
    public allowChangeValue: boolean;
    public recipient: string;
    public payer: string;
    public discountValue: number;
    public interestValueCalculated: number;
    public maxValue: number;
    public minValue: number;
    public fineValueCalculated: number;
    public originalValue: number;
    public totalUpdated: number;
    public totalWithDiscount: number;
    public totalWithAdditional: number;
}

export interface IPayBillResponseModal {
    recipient: string, 
    amount: number,
    transactionDate: Date;
}

export interface IBillCreated {
    transactionId: string;
    status: string;
    bankEmissor: string;
    bankNumber: string;
    bankAgency: string;
    bankAccount: string;
    barCode: string;
    bankLine: string;
    bankAssignor: string;
    dueDate: string;
    beneficiary: string;
    fine: string;
    interest: string;
    linkPix: string;
}

export interface IBillView {
    beneficiary: string;
    maturity: Date;
    value: string;
    barcode: string;
}

export interface IDebtorBill {
    number: string;
    neighborhood: string;
    name: string;
    document: string;
    city: string;
    publicArea: string;
    state: string;
    postalCode: string;
    noNumber: boolean;
    isCpfSelected: boolean;
}