/**
 * @param list The array to sort by date descending
 * @param datePropertyNameToSort The date property name to compare and sort
 * @returns The same array, but sorted by date (descending)
 */
export function sortDateDescending(
    list: Array<any> = [],
    datePropertyNameToSort: string,
): Array<any> {
    // Filter list by items that have the date property name to compare
    const filteredList: Array<any> = list.filter(
        item => item && item[`${datePropertyNameToSort}`],
    );

    // If have some item, let's sort!
    if (filteredList && filteredList.length > 0) {
        const sortedList = filteredList.sort((a, b) => {
            return b[`${datePropertyNameToSort}`] <
                a[`${datePropertyNameToSort}`]
                ? -1
                : b[`${datePropertyNameToSort}`] >
                  a[`${datePropertyNameToSort}`]
                ? 1
                : 0;
        });
        return sortedList;
    }
    // If there's no item, return the original list
    return list;
}